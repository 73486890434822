import 'isomorphic-fetch';
import _get from 'lodash/get';
import { createAbsoluteUrl } from '../../Utils/Url';
import { loadMomentCultureForApp } from '../../modules/MomentLocales/actions';
import I18n from '../react-i18nify-wbc/I18n';

export const LOAD_TRANSLATIONS = '@@i18n/LOAD_TRANSLATIONS';
export const loadTranslations = translations => dispatch => {
    dispatch({
        type: LOAD_TRANSLATIONS,
        translations,
    });
};

export const SET_LOCALE = '@@i18n/SET_LOCALE';
export const setLocale = locale => dispatch => {
    dispatch({
        type: SET_LOCALE,
        locale,
    });
    I18n.forceComponentsUpdate();
};
export const fetchTranslations = userLang => (dispatch, getState) => {
    const state = getState();
    return fetch(
        createAbsoluteUrl(`/api/utils/translations/portal/${userLang}/${_get(state, 'environment.translationsHash')}`)
    )
        .then(function(response) {
            return response.json();
        })
        .then(function(result) {
            dispatch(loadTranslations(JSON.parse(result)));
            dispatch(loadMomentCultureForApp(userLang));
            dispatch(setLocale(userLang));
        });
};
