//https://github.com/kylewelsby/window-location-origin

export function polyfillLocationOrigin() {
    const location = window.location;
    if (!location.origin) {
        const origin = location.protocol + '//' + location.hostname + (location.port && ':' + location.port);

        try {
            // Make it non editable
            Object.defineProperty(location, 'origin', {
                enumerable: true,
                value: origin,
            });
        } catch (e) {
            // IE < 8
            location.origin = origin;
        }
    }
}
