export const applications = {
    loadingStarted: 'APPS_LOADING_STARTED',
    loadingComplete: 'APPS_LOADING_COMPLETE',
    loadingFailed: 'APPS_LOADING_FAILED',
    singleApplicationLoadingComplete: 'APPS_SINGLE_APPLICATION_LOADING_COMPLETE',
    mostUsedLoadingComplete: 'APPS_MOST_USED_COMPLETE',
    currentApplicationSelected: 'APPS_CURRENT_APP_SELECTED',
    currentApplicationReset: 'APPS_RESET_SELECTION',
    changeDefaultDashboard: 'APPS_CHANGE_DEFAULT_DASHBOARD',
    changeNameApp: 'APPS_CHANGE_NAME',
    changeDescriptionApp: 'APPS_CHANGE_DESCRIPTION',
    addApplicationToCollection: 'APPS_ADD_APPLICATION_TO_COLLECTION',
    removeApplicationFromCollection: 'APPS_REMOVE_APPLICATION_FROM_COLLECTION',
};

export const historyInfo = {
    loading: 'ELEMENT_HISTORY_INFO_LOADING',
    loadingFailed: 'ELEMENT_HISTORY_INFO_LOADING_FAILED',
    loaded: 'ELEMENT_HISTORY_INFO_LOADED',
};

export const historyLite = {
    loading: 'ELEMENT_HISTORY_LOADING',
    loadingFailed: 'ELEMENT_HISTORY_LOADING_FAILED',
    loaded: 'ELEMENT_HISTORY_LOADED',
    versionLoading: 'ELEMENT_HISTORY_VERSION_LOADING',
    versionLoadingFailed: 'ELEMENT_HISTORY_VERSION_LOADING_FAILED',
    versionLoaded: 'ELEMENT_HISTORY_VERSION_LOADED',
    versionsCollapseAll: 'ELEMENT_HISTORY_VERSIONS_COLLAPSE_ALL',
    versionsExpand: 'ELEMENT_HISTORY_VERSIONS_EXPAND',
    versionsExpandAll: 'ELEMENT_HISTORY_VERSIONS_EXPAND_ALL',
    tasksLoading: 'ELEMENT_HISTORY_TASKS_LOADING',
    tasksLoadingFailed: 'ELEMENT_HISTORY_TASKS_LOADING_FAILED',
    tasksLoaded: 'ELEMENT_HISTORY_TASKS_LOADED',
    actionsLoading: 'ELEMENT_HISTORY_ACTIONS_LOADING',
    actionsLoadingFailed: 'ELEMENT_HISTORY_ACTIONS_LOADING_FAILED',
    actionsLoaded: 'ELEMENT_HISTORY_ACTIONS_LOADED',
};

export const modal = {
    open: 'MODAL_OPEN',
    close: 'MODAL_CLOSE',
    closeAll: 'MODAL_CLOSE_ALL',
};

export const applicationSelection = {
    modeChanged: 'APPLICATION_SELECTION_MODE_CHANGED',
    searchChanged: 'APPLICATION_SELECTION_SEARCH_CHANGED',
    indexChanged: 'APPLICATION_SELECTION_INDEX_CHANGED',
};

export const login = {
    initiateLogin: 'INITIATE_LOGIN',
    ntlmLogin: 'NTLM_LOGIN',
    aadLogin: 'AAD_LOGIN',
};

export const view = {
    loadingComplete: 'VIEW_LOADING_COMPLETE',
    singleLoadingComplete: 'VIEW_SINGLE_LOADING_COMPLETE',
    moveView: 'VIEW_MOVE',
    loadAllViewsFailed: 'VIEW_LOAD_ALL_FAILED',
};

export const popup = {
    close: 'POPUP_CLOSE',
    show: 'POPUP_SHOW',
};

export const logs = {
    store: 'LOGS_STORE',
    clear: 'LOGS_CLEAR',
    filterChanged: 'LOGS_FILTER_CHANGED',
    categoryChanged: 'LOGS_CATEGORY_CHANGED',
    clearFilters: 'LOGS_CLEAR_FILTERS',
    toggleCollapseNode: 'LOGS_TOGGLE_COLLAPSE_NODE',
    saveCurrentSession: 'LOGS_SAVE_CURRENT_SESSION',
    showCommitSessionModal: 'LOGS_SHOW_COMMIT_SESSION_MODAL',
    hideCommitSessionModal: 'LOGS_HIDE_COMMIT_SESSION_MODAL',
};

export const navigation = {
    toggleCollapse: 'NAVIGATION_TOGGLE_COLLAPSE',
};

export const environment = {
    viewportChanged: 'ENVIRONMENT_VIEWPORT_CHANGED',
    setUserLang: 'ENVIRONMENT_LANGUAGES_SETUSERLANG',
    setEmbeddedMode: 'ENVIRONMENT_EMBEDDED_MODE',
    setMSTeamsMode: 'ENVIRONMENT_MSTEAMS_MODE',
    startNewDebugSession: 'ENVIRONMENT_START_NEW_DEBUG_SESSION',
    enableDebug: 'ENVIRONMENT_ENABLE_DEBUG',
    disableDebug: 'ENVIRONMENT_DISABLE_DEBUG',
};

export const search = {
    searchValueChanged: 'SEARCH_SEARCH_VALUE_CHANGED',
    searchResultsLoaded: 'SEARCH_SEARCH_RESULTS_LOADED',
    searchIndexChanged: 'SEARCH_SEARCH_INDEX_CHANGED',
    setExclusiveSection: 'SEARCH_SET_EXCLUSIVE_SECTION',
    setDistributedResults: 'SEARCH_SET_DISTRIBUTED_RESULTS',
    clearSearch: 'SEARCH_CLEAR',
};

export const tasks = {
    tasksCountLoadingComplete: 'TASKS_LOADING_COMPLETE',
    taskSetFlag: 'TASK_SET_FLAG',
    taskSetFlagCompleted: 'TASK_SET_FLAG_COMPLETED',
    applicationTasksAddOrUpdate: 'TASK_APPLICATION_ADD_OR_UPDATE',
    applicationTasksRemove: 'TASK_APPLICATION_REMOVE',
    applicationTasksLoadingComplete: 'TASKS_APPLICATIONS_TASKS_LOADING_COMPLETE',
    applicationTasksMarkAsRead: 'TASKS_APPLICATIONS_MARK_AS_READ',
    applicationTasksLoading: 'TASKS_APPLICATIONS_TASKS_LOADING',
    applicationTasksClean: 'TASKS_APPLICATIONS_TASKS_CLEAN',
    applicationTasksElementChangeInvoked: 'TASK_ELEMENT_CHANGE_INVOKED',
    applicationTasksElementChangeFinished: 'TASK_ELEMENT_CHANGE_FINISHED',
    applicationTasksAvailableProcessesCompleted: 'TASKS_AVAILABLE_PROCESSES_COMPLETED',
    applicationTasksAvailableApplicationsCompleted: 'TASKS_AVAILABLE_APPLICATIONS_COMPLETED',
};

export const applicationEditMode = {
    toggleEditMode: 'TOGGLE_EDIT_MODE',
};

export const activities = {
    loading: 'ACTIVITIES_LOADING',
    loadingCompleted: 'ACTIVITIES_LOADING_COMPLETED',
};

export const suggestions = {
    set: 'SUGGESTIONS_SET',
    pin: 'PIN_SUGGESTION_ITEM',
    unPin: 'UNPIN_SUGGESTION_ITEM',
    pinState: 'PIN_SUGGESTION_ITEM_STATE',
    unPinState: 'UNPIN_SUGGESTION_ITEM_STATE',
};

export const processes = {
    set: 'PROCESSES_SET',
};

export const starts = {
    startsLoaded: 'STARTS_LOADED',
    startsForbidden: 'STARTS_FORBIDDEN',
    deleteStart: 'DELETE_START',
    editStart: 'EDIT_START',
    changeStartOrder: 'CHANGE_START_ORDER',
    changeStartOrderCompleted: 'CHANGE_START_ORDER_COMPLETED',
};

export const startAnyElementModal = {
    loadingComplete: 'STARTANYELEMENT_AVAILABLE_STARTS_LOADING_COMPLETE',
    selectedValueChanged: 'STARTANYELEMENT_SELECTED_VALUE_CHANGED',
    clearConfiguration: 'STARTANYELEMENT_CLEAR_CONFIGURATION',
    formTypeChange: 'FORM_TYPE_CHANGE_CHANGE',
};

export const dbConfig = {
    languagesLoaded: 'AVAILABLE_LANGUAGES_LOADED',
    portalLanguagePackageLoaded: 'PORTAL_LANGUAGE_PACKAGE_LOADED',
};

export const licences = {
    setLicenceInfo: 'SET_LICENCE_INFO',
    removeLicenceInfo: 'REMOVE_LICENCE_INFO',
};

export const favourites = {
    getFavourites: 'GET_FAVOURITES',
    favouritesLoaded: 'FAVOURITES_LOADED',
    favouritesError: 'FAVOURITES_ERROR',
    favouriteDeleted: 'FAVOURITE_DELETED',
    favouriteCreated: 'FAVOURITE_CREATED',
};
