import { I18n, Translate } from './react-i18nify-wbc';

export { I18n, Translate };

export {
    SET_LOCALE,
    LOAD_TRANSLATIONS,
    setLocale,
    loadTranslations,
    fetchTranslations,
} from './react-redux-i18n-wbc/actions';

export { default as i18nReducer } from './react-redux-i18n-wbc/reducer';

export function syncTranslationWithStore(store) {
    I18n.setTranslationsGetter(() => {
        try {
            return store.getState().i18n.translations;
        } catch (e) {
            console.error('Error getting translations from store!');
            return {};
        }
    });
    I18n.setLocaleGetter(() => {
        try {
            return store.getState().i18n.locale;
        } catch (e) {
            console.error('Error getting locale from store!');
            return 'en';
        }
    });
}
