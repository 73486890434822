import React, { PureComponent } from 'react';
import Image from '../Utils/Image';
import './LoginApp.scss';
import Translate from '../Libs/react-i18nify-wbc/Translate';

export default class LoginOutlookView extends PureComponent {
    componentDidMount() {
        window.addEventListener('load', this.OutlookLogin);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.OutlookLogin);
    }

    OutlookLogin = () => {
        const url = `${window.location.href.substr(0, window.location.href.indexOf('?'))}?ReturnUrl=${encodeURI(
            window.__webpack_public_path__
        )}%2Faddins%2FFinishLogin.html`;

        parent.postMessage(url, '*');
    };

    render() {
        return (
            <div className="login__background">
                <div className="ms-slideDownIn20 login__box">
                    <div className="login__container">
                        <div>
                            <Image className="login__logo" src="/images/logo_gray.png" />
                        </div>
                        <h3 className="login__title">
                            <strong>WEBCON BPS</strong> Portal
                        </h3>
                        <a className="btn login__provider-button" href="#" onClick={this.OutlookLogin}>
                            <Translate className="login__button-text" value="login.outlookLoginPopup" />
                        </a>
                        <p>
                            <small className="login__created-on">
                                <Translate value="login.createdOn" /> {` ${new Date().getFullYear()}`}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
