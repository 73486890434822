import { createBrowserHistory as createHistory } from 'history';

const history = createHistory({ basename: window.__webpack_public_path__ });

export const push = (path, state) => history.push(path, state);

export const location = () => history.location.pathname;

export const replace = path => history.replace(path);

export default history;
