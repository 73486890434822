export const LOAD_MOMENT_CULTURE_FOR_FORM = 'LOAD_MOMENT_CULTURE_FOR_FORM';
export const LOAD_MOMENT_CULTURE_FOR_APP = 'LOAD_MOMENT_CULTURE_FOR_APP';

export const loadMomentCultureForForm = culture => ({
    type: LOAD_MOMENT_CULTURE_FOR_FORM,
    payload: {
        culture,
    },
});
export const loadMomentCultureForApp = culture => ({
    type: LOAD_MOMENT_CULTURE_FOR_APP,
    payload: {
        culture,
    },
});
