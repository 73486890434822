import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import LoginViewContainer from './LoginViewContainer';
import Image from '../Utils/Image';
import UnauthorizedStudioUserViewContainer from './UnauthorizedStudioUserViewContainer';
import { I18n } from '../Libs/react-redux-i18n-wbc';
import './LoginApp.scss';

export default class LoginAppView extends PureComponent {
    render() {
        return (
            <div className="login__background">
                <div className="ms-slideDownIn20 login__box">
                    <div className="login__container">
                        <div>
                            <Image className="login__logo" src="/images/logo_gray.png" />
                        </div>
                        <h3 className="login__title">
                            <strong>WEBCON BPS</strong> Portal
                        </h3>
                        <Route path="/account/login" component={LoginViewContainer} />
                        <Route
                            path="/account/HandleUnauthorizedStudioUser"
                            component={UnauthorizedStudioUserViewContainer}
                        />
                        <p>
                            <small>
                                <a className="login__logout" href="https://auth.webconbps.com/logout.aspx?return=true">
                                    {I18n.t('login.logoutFromBpsAuth')}
                                </a>
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
