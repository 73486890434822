import '../polyfills.js';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { i18nReducer } from '../Libs/react-redux-i18n-wbc';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'normalize.css';
import '../Libs/bootstrap/css/bootstrap.scss';
import '../../Styles/global.scss';
import '../Libs/font-open-sans/font-open-sans.scss';
import '../MainView.scss';
import { setPublicPathProvider } from '../Utils/Url';
import { Router } from 'react-router-dom';
import history from '../history';
import LoginAppView from './LoginAppView.js';
import { fetchTranslations, setLocale, syncTranslationWithStore } from '../Libs/react-redux-i18n-wbc';
import EnvironmentReducer from '../Environment/EnvironmentReducer.js';
import LoginMSTeamsView from './LoginMSTeamsView';
import LoginOutlookView from './LoginOutlookView';
import LoginSharePointView from './LoginSharePointView';

(function() {
    setPublicPathProvider(() => {
        return window.__webpack_public_path__;
    });

    const initialState = {
        environment: {
            ...window.initModel.environment,
            login: window.initModel.externalProviders,
        },
    };

    let store = createStore(
        combineReducers({
            i18n: i18nReducer,
            environment: EnvironmentReducer,
        }),

        initialState,
        composeWithDevTools(applyMiddleware(thunk))
    );
    let env = store.getState().environment;
    syncTranslationWithStore(store);
    store.dispatch(fetchTranslations(env.userLang));
    store.dispatch(setLocale(env.userLang));

    function RenderLoginView() {
        if (window.location.href.indexOf('embedMode%3DmsTeams') > 0) {
            return <LoginMSTeamsView />;
        } else if (window.location.href.indexOf('embedMode%3Doutlook') > 0) {
            return <LoginOutlookView />;
        } else if (window.location.href.indexOf('embedMode%3Dsharepoint') > 0) {
            return <LoginSharePointView />;
        } else {
            return <LoginAppView />;
        }
    }

    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <RenderLoginView />
            </Router>
        </Provider>,
        document.getElementById('RootFrame')
    );
})();
