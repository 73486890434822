import React, { createContext, memo, useState } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import I18n from '../../Libs/react-i18nify-wbc/I18n';

const createValue = memoizeOne(locale => {
    return {
        locale: locale,
        t: (key, replacements) => {
            const translated = I18n.t(key, replacements, locale);

            return translated;
        },
    };
});

export const LocalizationContextObject = createContext(createValue(null));

LocalizationContextObject.displayName = 'LocalizationContext';

/**
 * Ustawia wybrany język w Reactowym kontekście komponentu.
 */
const LocalizationContext = memo(({ children, locale }) => {
    const [value, setValue] = useState(null);
    const [prevLocale, setPrevLocale] = useState(null);

    if (locale !== prevLocale) {
        setValue(createValue(locale));
        setPrevLocale(locale);
    }

    return <LocalizationContextObject.Provider value={value}>{children}</LocalizationContextObject.Provider>;
});

LocalizationContext.propTypes = {
    locale: PropTypes.string,
};

LocalizationContext.type.displayName = 'LocalizationContext';

export default LocalizationContext;
