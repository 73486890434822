import React from 'react';
import { createAbsoluteUrl } from './Url';

const Image = React.memo(
    ({ isUrlAbsolute, src, getImgRef, alt, onLoad, onError, className, preventDrag, ...restProps }) => (
        <img
            src={isUrlAbsolute ? src : createAbsoluteUrl(src)}
            alt={alt}
            draggable={!preventDrag}
            onDragStart={e => {
                if (preventDrag) e.preventDefault();
            }}
            onError={onError}
            onLoad={onLoad}
            ref={getImgRef}
            className={className}
            {...restProps}
        />
    )
);

Image.type.displayName = 'Image';

Image.defaultProps = {
    isUrlAbsolute: false,
};

export default Image;
