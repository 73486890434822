import { environment } from '../ActionTypes';
import { SET_NATIVE_MOBILE_MODE } from '../modules/Environment/actions';
import { v4 as uuid } from 'uuid';

const EnvironmentReducer = (state = {}, action) => {
    switch (action.type) {
        case environment.setUserLang:
            return {
                ...state,
                userLang: action.newUserLang,
            };
        case environment.viewportChanged:
            return {
                ...state,
                viewport: action.viewport,
            };
        case environment.setEmbeddedMode:
            return {
                ...state,
                embedded: true,
            };
        case environment.setMSTeamsMode:
            return {
                ...state,
                MSTeamsMode: true,
            };
        case environment.startNewDebugSession:
            return {
                ...state,
                previousDebugId: state.debugId,
                debugId: uuid(),
            };
        case environment.enableDebug:
            return {
                ...state,
                isDebug: true,
            };
        case environment.disableDebug:
            return {
                ...state,
                isDebug: false,
            };
        case SET_NATIVE_MOBILE_MODE:
            return {
                ...state,
                nativeMobileMode: true,
            };
        default:
            return state;
    }
};

export default EnvironmentReducer;

window.webcon = (function(webcon) {
    webcon.redux = {
        ...webcon.redux,
        EnvironmentReducer,
    };
    return webcon;
})(window.webcon || {});
