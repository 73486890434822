import URL from 'url-parse';
import { buildPath } from './pathBuilder';
import startsWith from 'lodash/startsWith';
import { parse, stringify } from 'querystringify';
import mapKeys from 'lodash/mapKeys';
import window from '../window';
import _isNil from 'lodash/isNil';

let absoluteUrlCreator = undefined;
let absoluteUrlRemover = undefined;
let basePublicPath = undefined;
let isUrlExternalChecker = undefined;

export let setPublicPathProvider = getPublicPath => {
    absoluteUrlCreator = internalCreateAbsoluteUrl(getPublicPath);
    absoluteUrlRemover = internalRemoveAbsoluteUrl(getPublicPath);
    isUrlExternalChecker = internalisUrlExternalChecker(getPublicPath);
    basePublicPath = getPublicPath();
};

let internalCreateAbsoluteUrl = getPublicPath => address => {
    const prefix = getPublicPath();
    return createAbsoluteUrlForPublicPath(prefix, address);
};

let internalRemoveAbsoluteUrl = getPublicPath => address => {
    const prefix = getPublicPath();
    return removeAbsoluteUrlForInternalPath(prefix, address);
};

let internalisUrlExternalChecker = getPublicPath => (currentLocation, address) => {
    const prefix = getPublicPath();
    return checkIfUrlIsExternal(prefix, currentLocation, address);
};

export function trimSlashes(value) {
    return value.replace(/^\/+|\/$/gm, ''); // trimuję slashe z początku i z końca
}

export function removeAbsoluteUrlForInternalPath(publicPath = '', address) {
    if (!publicPath || !address) {
        return address;
    }

    publicPath = trimSlashes(publicPath);
    if (!publicPath) return address;

    const url = new URL(address);
    let pathname = url.pathname;
    const breakIndex = pathname.indexOf(publicPath);
    if (breakIndex === -1) {
        return address;
    }
    pathname = pathname.substring(breakIndex + publicPath.length);
    return pathname + url.query;
}

export function createAbsoluteUrlForPublicPath(publicPath = '', address) {
    if (!address) throw new Error('Address null');

    if (address.indexOf('/') !== 0 && publicPath.lastIndexOf('/') !== publicPath.length - 1) address = '/' + address;

    if (address.indexOf('/') === 0 && publicPath.lastIndexOf('/') === publicPath.length - 1)
        address = address.substring(1);

    if (publicPath.indexOf('http') === 0 || publicPath.indexOf('/') === 0) return publicPath + address;

    return '/' + publicPath + address;
}

export function createAbsoluteUrlForPublicPathWithFallback(publicPath, address) {
    if (basePublicPath) {
        publicPath = basePublicPath;
    }

    return createAbsoluteUrlForPublicPath(publicPath, address);
}

export function createAbsoluteUrl(address) {
    if (address === '' || typeof address === 'undefined' || address === null || address.indexOf('http') === 0)
        return address;

    return absoluteUrlCreator(address);
}

export function createAbsoluteUrlWithPrefix(address) {
    const url = createAbsoluteUrl(address);
    const urlObj = new URL(url);
    return urlObj.href;
}

export function removeAbsoluteUrl(address) {
    if (address === '' || typeof address === 'undefined') return address;

    return absoluteUrlRemover(address);
}

function isExclusivelyExtraExternal(url, publicPath) {
    return startsWith(url, 'public/') || !!(publicPath && startsWith(url, publicPath + '/public/'));
}

export function isUrlExternal(url, win = window) {
    if (!url) throw new Error('url is empty');

    return isUrlExternalChecker(win.location, url);
}

function isAbsoluteUrl(address) {
    return startsWith(address, 'http');
}

export function fixUrlProtocol(url) {
    if (startsWith(url, 'www.')) {
        return 'http://' + url;
    }
    return url;
}

export function checkIfUrlIsExternal(publicPath, currentLocation, addressToCheck) {
    addressToCheck = fixUrlProtocol(addressToCheck);
    // adresy w portalu są bez publicPath, jeżeli ktoś użyje pełnego adresu, wtedy trzeba gsprawdzić czy jest ok
    const urlToCheck = new URL(addressToCheck, currentLocation);
    const areHostsDifferent = currentLocation.host !== urlToCheck.host;
    let addressPathStartsWithPublicPath = true;
    let isExclusivelyExternal = false;

    if (!areHostsDifferent) {
        const trimmedPath = trimSlashes(urlToCheck.pathname);
        const isAbsolute = isAbsoluteUrl(addressToCheck);
        publicPath = trimSlashes(publicPath);
        if (isAbsolute) {
            if (publicPath) {
                const startsWithPath = publicPath + '/';
                addressPathStartsWithPublicPath = startsWith(trimmedPath, startsWithPath) || trimmedPath === publicPath;
            }
        }
        isExclusivelyExternal = !addressPathStartsWithPublicPath || isExclusivelyExtraExternal(trimmedPath, publicPath);
    }

    return areHostsDifferent || !addressPathStartsWithPublicPath || isExclusivelyExternal;
}

export function createAppBaseUrl(dbId, appId) {
    return buildPath('db', dbId, 'app', appId);
}

export function createProjectBaseUrl(dbId, appId) {
    return buildPath('designer', 'db', dbId, 'projects', appId);
}

export function createDeskBaseUrl(dbId, appId) {
    return buildPath('designer', 'db', dbId, 'projects', appId);
}

export function parseQueryToLowerKeys(query) {
    const queryObj = parse(query);
    return mapKeys(queryObj, (value, key) => key.toLowerCase());
}

export const createEmbedBaseUrl = (dbId, appId, embedded) => {
    if (embedded) return createAbsoluteUrl(buildPath('embed', 'v1', 'db', dbId, 'app', appId));
    return createAppBaseUrl(dbId, appId);
};

export const createDesignerDeskBaseUrl = (dbId, appId) => {
    return buildPath('designer', 'db', dbId, 'projects', appId);
};

export const replaceUrlHostWithCurrent = url => {
    const urlObj = URL(url);
    urlObj.set('host', window.location.host);
    return urlObj.href;
};

export const copyQueryParamToUrlObjFromCurrent = (url, param) => {
    const urlObj = URL(url);
    const currentQuery = parse(window.location.search);
    if (_isNil(currentQuery[param])) return urlObj;
    const query = { ...parse(urlObj.query), [param]: currentQuery[param] };
    urlObj.set('query', stringify(query, '?'));
    return urlObj;
};

export const copyQueryParamToPathnameFromCurrent = (pathname, param) => {
    const urlObj = copyQueryParamToUrlObjFromCurrent(pathname, param);
    return `${urlObj.pathname}${stringify(urlObj.query, '?')}`;
};

export const copyQueryParamsToUrlObjFromCurrent = url => {
    const urlObj = URL(url);
    const currentQuery = parse(window.location.search);
    const query = { ...parse(urlObj.query), ...currentQuery };
    urlObj.set('query', stringify(query, '?'));
    return urlObj;
};

export const copyQueryParamsToPathnameFromCurrent = pathname => {
    const urlObj = copyQueryParamsToUrlObjFromCurrent(pathname);
    return `${urlObj.pathname}${stringify(urlObj.query, '?')}`;
};
