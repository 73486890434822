import React, { PureComponent, Fragment } from 'react';
import { I18n } from '../Libs/react-redux-i18n-wbc';

export default class LoginView extends PureComponent {
    render() {
        return (
            <Fragment>
                <p className="login__sign-up">{I18n.t('login.signIn')}:</p>
                {this.props.providers.map(pr => {
                    return (
                        <a key={pr.id} className="btn login__provider-button" href={pr.authUrl}>
                            <span className="login__button-text">{pr.name}</span>
                        </a>
                    );
                })}
            </Fragment>
        );
    }
}
