import React, { PureComponent } from 'react';
import Image from '../Utils/Image';
import './LoginApp.scss';
import Translate from '../Libs/react-i18nify-wbc/Translate';
import { parse } from 'querystringify';

const delayPopup = 1000;
export default class LoginMSTeamsView extends PureComponent {
    componentDidMount() {
        window.addEventListener('DOMContentLoaded', this.MSTeamsLogin);
    }

    componentWillUnmount() {
        window.removeEventListener('DOMContentLoaded', this.MSTeamsLogin);
    }

    MSTeamsLogin = () => {
        window.microsoftTeams.initialize();
        setTimeout(function() {
            window.microsoftTeams.authentication.authenticate({
                url: `${window.location.href.substr(0, window.location.href.indexOf('?'))}?ReturnUrl=${encodeURI(
                    window.__webpack_public_path__
                )}%2FAssets%2Fmsteams%2FFinishLogin.html`,
                width: 600,
                height: 535,
                successCallback: function() {
                    let queryValues = parse(window.location.search);
                    if (queryValues.ReturnUrl) {
                        let returnUrl = queryValues.ReturnUrl;
                        window.location = returnUrl;
                    }
                },
                failureCallback: function(reason) {
                    if (reason !== 'CancelledByUser' && reason !== 'FailedToOpenWindow') alert(reason);
                },
            });
        }, delayPopup);
    };
    render() {
        return (
            <div className="login__background">
                <div className="ms-slideDownIn20 login__box">
                    <div className="login__container">
                        <div>
                            <Image className="login__logo" src="/images/logo_gray.png" />
                        </div>
                        <h3 className="login__title">
                            <strong>WEBCON BPS</strong> Portal
                        </h3>
                        <a className="btn login__provider-button" href="#" onClick={this.MSTeamsLogin}>
                            <Translate className="login__button-text" value="login.teamsLoginPopup" />
                        </a>
                        <p>
                            <small className="login__created-on">
                                <Translate value="login.createdOn" /> {` ${new Date().getFullYear()}`}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
