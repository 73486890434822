import 'core-js/fn/array/fill';
import 'core-js/fn/array/from';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/ends-with';
import 'core-js/fn/string/includes';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/flat-map';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/object/values';
import 'core-js/fn/object/entries';
import 'regenerator-runtime/runtime';
import 'classlist-polyfill';
import 'raf/polyfill';
import 'mutationobserver-shim';
import 'svg-dataset-polyfill';
import 'path2d-polyfill';
import './Utils/Polyfills/matchMediaPolyfill';
import './Utils/Polyfills/elementClosest';
import { Promise } from 'es6-promise-polyfill';
import es6ObjectAssign from 'es6-object-assign';
import { polyfill } from './Utils/Polyfills/mathSignPolyfill';
import { polyfillLocationOrigin } from './Utils/Polyfills/windowLocationPolyfill';
import { polyfillPromiseFinally } from './Utils/Polyfills/promiseFinallyPolyfill';
import arrayFlatPolyfill from './Utils/Polyfills/arrayFlatPolyfill';
import isMobile from 'ismobilejs';

polyfill();
polyfillLocationOrigin();
arrayFlatPolyfill();

window.Promise = Promise;
polyfillPromiseFinally();

es6ObjectAssign.polyfill();

const doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);
if (isMobile().any) {
    doc.classList.add('mobile');
}
