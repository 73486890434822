import { connect } from 'react-redux';
import get from 'lodash/get';
import UnauthorizedStudioUserView from './UnauthorizedStudioUserView';

function mapStateToProps(state) {
    const { login, displayName } = get(state, 'environment.user', {});
    return {
        login,
        displayName,
    };
}

export default connect(mapStateToProps)(UnauthorizedStudioUserView);
