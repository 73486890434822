//https://developer.mozilla.org/pl/docs/Web/JavaScript/Referencje/Obiekty/Array/flat

export default () => {
    if (!Array.prototype.flat) {
        Array.prototype.flat = function(depth) {
            let flattend = [];
            (function flat(array) {
                for (let el of array) {
                    if (Array.isArray(el) && depth > 0) {
                        flat(el, depth - 1);
                    } else {
                        flattend.push(el);
                    }
                }
            })(this, Math.floor(depth) || 1);
            return flattend;
        };
    }
};
