import React, { PureComponent, Fragment } from 'react';
import { parse, stringify } from 'querystringify';
import { createAbsoluteUrl } from '../Utils/Url';
import { I18n } from '../Libs/react-redux-i18n-wbc';

export default class UnauthorizedStudioUserView extends PureComponent {
    render() {
        const { login, displayName } = this.props;
        const { redirect_uri } = parse(window.location.search);
        const endpoint = redirect_uri ? '/account/tokenLogin' : '/account/studioLogin';
        const cancelUrl = createAbsoluteUrl(`${endpoint}${redirect_uri ? stringify({ redirect_uri }, true) : ''}`);
        const tokenUrl = createAbsoluteUrl(`${endpoint}${window.location.search}`);

        const logoutReturnUrlParams = stringify({ returnUrl: tokenUrl }, true);
        const signAsAnotherUserUrl = createAbsoluteUrl(`/account/logout${logoutReturnUrlParams}`);
        return (
            <Fragment>
                <h3>{`${I18n.t('login.signedInAs')}:`}</h3>
                <div className="widget lazur-bg p-xl">
                    <h2>{displayName}</h2>
                    <h3>{login}</h3>
                </div>

                <h4>{I18n.t('login.noRightsForStudio')}</h4>

                <a className="btn btn-info btn-block" href={signAsAnotherUserUrl}>
                    {I18n.t('login.signInAsDifferentUser')}
                </a>
                <a className="btn btn-default btn-block" href={cancelUrl}>
                    {I18n.t('login.cancel')}
                </a>
            </Fragment>
        );
    }
}
