import { connect } from 'react-redux';
import LoginView from './LoginView';
import get from 'lodash/get';

function mapStateToProps(state) {
    const providers = get(state, 'environment.login.providers', []);
    return {
        providers: providers,
    };
}

export default connect(mapStateToProps)(LoginView);
