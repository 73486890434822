import { Promise } from 'es6-promise-polyfill';

export function polyfillPromiseFinally() {
    //https://stackoverflow.com/questions/53327711/how-to-add-a-polyfill-to-support-finally-in-edge
    window.Promise.prototype.finally =
        window.Promise.prototype.finally ||
        {
            finally(fn) {
                const onFinally = value => Promise.resolve(fn()).then(() => value);
                return this.then(
                    result => onFinally(result),
                    reason => onFinally(Promise.reject(reason))
                );
            },
        }.finally;
}
