import omitBy from 'lodash/omitBy';
import { stringify, parse } from 'querystringify';
import { parseQueryToLowerKeys } from './Url';
import { trimSlashes } from 'Utils/Url';
const slash = '/';

function joinPartsToPath() {
    const argsLength = arguments.length;
    const parts = [];
    if (argsLength !== 0) {
        for (let i = 0; i < argsLength; i++) {
            let part = (arguments[i] !== undefined && arguments[i] !== null ? arguments[i] : '').toString();
            part = trimSlashes(part);

            if (part) {
                parts.push(part);
            }
        }

        if (parts.length > 0) {
            return parts.join(slash);
        }
    }
    return null;
}

export function buildPath() {
    const innerPath = joinPartsToPath.apply(this, arguments);
    if (innerPath) {
        return `/${innerPath}`;
    }
    return null;
}

export function addQueryStringParamToUrlWithoutCaseChanging(url, param, value) {
    const urlParts = url.split('?');
    const urlWithQueryString = 2;
    if (urlParts.length === urlWithQueryString) {
        return url + `&${param.toLowerCase()}=${value}`;
    } else {
        return url + `?${param.toLowerCase()}=${value}`;
    }
}

export function addQueryStringParamToUrl(url, param, value) {
    // nie chcę modyfikować samego urla tylko jego queryParams
    const urlParts = url.split('?');
    const urlWithQueryString = 2;
    if (urlParts.length === urlWithQueryString) {
        urlParts[1] = addQueryStringParam(urlParts[1], param, value);
    } else {
        urlParts.push(value ? stringify({ [param.toLowerCase()]: value }, true) : '');
    }
    return `${urlParts[0]}${urlParts[1]}`;
}

export function addQueryStringParam(queryString, param, value) {
    return addQueryParametersToQueryString(queryString, [{ key: param, value }]);
}

export function addQueryParametersToQueryString(queryString = '', parameters = []) {
    const queryObject = parseQueryToLowerKeys(queryString);
    parameters.forEach(parameter => {
        const key = parameter.key.toLowerCase();
        queryObject[key] = parameter.value;
    });
    return stringify(queryObject, true);
}

export function buildPathWithQueryParams(pathParts, queryParameters) {
    const innerPath = joinPartsToPath.apply(this, pathParts);
    let query = '';

    if (queryParameters) {
        query = stringify(queryParameters, true);
    }

    if (innerPath) {
        return `/${innerPath}${query}`;
    }
    return null;
}

export const removeQueryParametersFromList = (queryString, parametersToExclude) => {
    const query = parse(queryString);
    const fixedQuery = omitBy(query, (value, key) => parametersToExclude.indexOf(key.toLowerCase()) >= 0);
    return stringify(fixedQuery, false);
};
